import { CoursesExtraLink } from "components/Navigation/CoursesExtraLink";
import { HighlightedCourse } from "components/Navigation/HighlightedCourse";
import { useTranslation } from "features/translations";
import { useMemo } from "react";
import { Heading4, PageLink, Paragraph } from "shared/components";

import styles from "./CoursesMenu.module.scss";
import { groupItems } from "./CoursesMenu.utils";

export const CoursesMenu = ({ items, caption }) => {
  const { t } = useTranslation();

  const { highlighted, groups } = useMemo(() => groupItems(items), [items]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.groups}>
        {groups.map(({ label, items }) => (
          <div className={styles.group} key={label}>
            <div className={styles.paper}>
              <Heading4 className={styles.title}>{label}</Heading4>
              <div className={styles.items}>
                {items.map(({ title, url, target, caption }) => (
                  <div className={styles.item} key={title}>
                    <PageLink
                      link={url}
                      target={target}
                      className={styles.link}
                    >
                      <Paragraph
                        color="dark"
                        appearance="bold"
                        className={styles.subtitle}
                      >
                        {title}
                      </Paragraph>
                    </PageLink>
                    <Paragraph
                      className={styles.caption}
                      color="dark"
                      appearance="smaller"
                    >
                      {caption}
                    </Paragraph>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.buttons}>
        {highlighted && <HighlightedCourse data={highlighted} />}
        <span className={styles.divider} />
        <CoursesExtraLink
          to="/programs/comparison"
          title={t("comparison_title")}
          description={t("comparison_caption")}
        />
        <span className={styles.divider} />
        <CoursesExtraLink
          to="/programs"
          title={t("all_programs_title")}
          description={caption || t("all_programs_caption")}
        />
      </div>
    </div>
  );
};
