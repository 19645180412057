import { PageLink, Paragraph } from "shared/components";

import styles from "./DropdownLinks.module.scss";

export const DropdownLinks = ({ items }) => {
  return items?.map(({ title, url, target }) => (
    <div className={styles.wrapper} key={title}>
      <PageLink link={url} target={target} className={styles.link}>
        <Paragraph color="dark" appearance="bold">
          {title}
        </Paragraph>
      </PageLink>
    </div>
  ));
};
