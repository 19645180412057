export const teacherWidgetsRegex = /{{teacher:[a-zA-Z0-9,-]+}}/g;
export const teacherLinkWidgetsRegex = /{{teacher_link:[a-zA-Z0-9,-]+}}/g;
export const teacherTimingWidgetsRegex =
  /{{teacher_timing:[a-zA-Z0-9,-]+:\s*{[а-яА-Яa-zA-Z0-9,\s*]+}\s*}}/g;

export const WIDGETS_REGEXES = [
  teacherWidgetsRegex,
  teacherLinkWidgetsRegex,
  teacherTimingWidgetsRegex,
];
