export const formatDate = (date, locale) => {
  if (date) {
    return new Intl.DateTimeFormat(locale, {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(new Date(date));
  }
  return null;
};
