import classNames from "classnames";
import { Action, Icon, Portal } from "shared/components";

import styles from "./ModalWrapper.module.scss";

export const ModalWrapper = ({ children, appearance, onCloseModal }) => {
  const onPrevent = (e) => e.stopPropagation();

  return (
    <Portal>
      <div className={styles.wrapper} onClick={onCloseModal}>
        <div
          className={classNames(styles.inner, styles[appearance])}
          onClick={onPrevent}
        >
          <Action className={styles.close} onClick={onCloseModal}>
            <Icon className={styles["close-icon"]} name="close" theme="grey" />
          </Action>
          {children}
        </div>
      </div>
    </Portal>
  );
};
