import classNames from "classnames";
import { FormContent } from "components/Forms";
import { useTranslation } from "features/translations";
import { useContext } from "react";

import styles from "./InputText.module.scss";

export const InputText = ({ type, name, placeholder }) => {
  const { t } = useTranslation();
  const { onChange, onValidate, getInputValue, getInputError } =
    useContext(FormContent);

  const value = getInputValue(name);
  const errors = getInputError(name);

  return (
    <div
      className={classNames(
        styles["input-type"],
        styles[`input-type--${type}`]
      )}
    >
      <input
        type={type}
        name={name}
        placeholder={t(placeholder)}
        className={classNames(styles.input, {
          [styles["has-error"]]: errors?.required || errors?.pattern,
        })}
        value={value}
        onChange={onChange}
        onBlur={onValidate}
      />
      {errors?.required && (
        <span className={styles.error}>{t("error_required")}</span>
      )}
    </div>
  );
};
