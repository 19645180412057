import { RichText } from "components/RichTextWidget";
import { DownloadFile } from "shared/components";

import styles from "./AttachmentsList.module.scss";

export const AttachmentsList = ({ items }) => (
  <div className={styles.wrapper}>
    {items.map((attachment) => (
      <div className={styles.file} key={attachment.id}>
        <RichText value={attachment.description} />
        <DownloadFile file={attachment.media} />
      </div>
    ))}
  </div>
);
