import { fetchAPI } from "features/cms";
import { logError } from "features/logger";

const defaultValue = {
  seo: {},
  hero: [],
  content: [],
  footer: [],
};

export const fetchSinglePageData = async (
  { locale, query, debug },
  { path = "/pages", defaultSlug = "home" } = {}
) => {
  const resolvedSlug = query?.slug ?? defaultSlug;

  try {
    const res = await fetchAPI(
      path,
      {
        filters: {
          slug: {
            $eq: resolvedSlug,
          },
        },
      },
      locale
    );

    const currentData = res?.data?.[0];

    if (!currentData) {
      return Promise.resolve({
        data: defaultValue,
        error: {
          status: 204,
        },
        debugData: debug
          ? {
              scope: "Data loaded and but not provided",
              error: {
                status: 204,
                message: "No data",
              },
              response: res,
            }
          : null,
      });
    }

    const { seo, hero, content, footer, ...pageOwnData } = currentData ?? {};

    return Promise.resolve({
      data: {
        seo: seo ?? {},
        hero: [hero?.[0]].filter(Boolean),
        content: content ?? [],
        footer: footer ?? [],
        pageOwnData: pageOwnData ?? {},
      },
      error: null,
      debugData: debug
        ? {
            scope: "Data loaded and provided",
            error: null,
            response: res,
          }
        : null,
    });
  } catch (e) {
    logError(e, { scope: "fetchSinglePageData", collection: "pages" });
    return Promise.resolve({
      data: defaultValue,
      error: {
        status: e.status,
      },
      debugData: debug
        ? {
            scope: "Error caught",
            error: {
              status: e.status,
              message: e.message,
            },
            response: null,
          }
        : null,
    });
  }
};
