import { useRouter } from "next/router";

import { InlineLanguages } from "./InlineLanguages";
import { InteractiveLanguages } from "./InteractiveLanguages";
import styles from "./LanguageSelector.module.scss";

export const LanguageSelector = ({ inline = false, appearance = "dark" }) => {
  const {
    pathname,
    locale,
    locales,
    query: { slug },
  } = useRouter();

  const currentHref = slug ? { pathname, query: { slug } } : pathname;

  const props = {
    locales,
    locale,
    currentHref,
  };

  const LanguagesVariation = inline ? InlineLanguages : InteractiveLanguages;

  return (
    <div className={styles[appearance]}>
      <LanguagesVariation {...props} />
    </div>
  );
};
