import { useContext, useMemo } from "react";

import { defaultValue, PageDataContext } from "./PageData.context";

export function usePageData() {
  const { data } = useContext(PageDataContext);

  return data ?? defaultValue.data;
}

export function useCollectedData(builtWidgetId, fields) {
  const { collectedData } = useContext(PageDataContext);

  return useMemo(
    () =>
      fields.reduce((acc, field) => {
        return {
          ...acc,
          [field.name]:
            collectedData?.[`${builtWidgetId}.${field.name}`] ??
            field.defaultValue,
        };
      }, {}),
    [builtWidgetId, collectedData, fields]
  );
}
