import classNames from "classnames";
import { RichText } from "components/RichTextWidget";
import React, { useState } from "react";
import { Heading6, Icon, Paragraph } from "shared/components";

import styles from "./AccordionList.module.scss";

export const AccordionList = ({
  items,
  appearance,
  color = "dark",
  className,
  togglePlus,
}) => {
  const formatCounter = (number) => (number > 9 ? number : `0${number}`);

  const [selected, setSelected] = useState(null);

  const onOpen = (id) => (e) => {
    if (e.target) {
      setSelected(id === selected ? null : id);
    }
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <ol className={styles.list}>
        {items.map((item, index) => (
          <li
            className={classNames(styles["list-item"], {
              [styles.opened]: selected === item.id,
            })}
            key={item.id}
            onClick={onOpen(item.id)}
          >
            {togglePlus ? (
              <div className={styles.circle}></div>
            ) : (
              <Heading6
                color="brand"
                as="span"
                className={styles.subtitle}
                plain={true}
              >
                {item.subtitle || formatCounter(index + 1)}
              </Heading6>
            )}
            <Paragraph
              as="p"
              className={classNames(styles.title, styles[appearance])}
            >
              {item.title}
            </Paragraph>
            <div className={styles.description}>
              <RichText color={color} value={item.description} />
            </div>

            {!togglePlus && item.description && (
              <div className={styles.dropdown}>
                <Icon name="dropdown" className={styles.icon} />
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
