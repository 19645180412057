import { useState } from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";

import styles from "./SwiperLayout.module.scss";

export const SwiperLayout = ({
  children,
  spaceBetween,
  slidesPerView,
  slidesPerGroup,
  paginationWrapper,
  prevRef,
  nextRef,
  watchOverflow,
  slidesOffsetAfter,
  loop = false,
  observer = true,
}) => {
  const [_, setInit] = useState(null);

  return (
    <Swiper
      watchOverflow={watchOverflow}
      observer={observer}
      modules={[Navigation, Pagination]}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      pagination={{
        el: `.${paginationWrapper}`,
        clickable: true,
        type: "fraction",
        renderFraction: (currentClass, totalClass) => {
          return `<span class="${currentClass} ${styles["pagination-current"]}"></span><span class="${totalClass}"></span>`;
        },
      }}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      slidesPerGroup={slidesPerGroup}
      slidesOffsetAfter={slidesOffsetAfter}
      onInit={() => {
        setInit(true);
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
      }}
      loop={loop}
    >
      <div>{children}</div>
    </Swiper>
  );
};
