import classNames from "classnames";
import { CoursesExtraLink } from "components/Navigation/CoursesExtraLink";
import { useTranslation } from "features/translations";
import { useCallback, useMemo, useState } from "react";
import { PageLink, Paragraph } from "shared/components";

import { groupItems } from "../CoursesMenu/CoursesMenu.utils";
import styles from "./CoursesMobileMenu.module.scss";

export const CoursesMobileMenu = ({ items, caption }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(null);

  const { groups } = useMemo(() => groupItems(items), [items]);

  const onToggle = useCallback(
    (label) => () => {
      setOpened((current) => (current === label ? null : label));
    },
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.groups}>
        {groups.map(({ label, items }) => (
          <div
            className={classNames(styles.group, {
              [styles.disabled]: opened && opened !== label,
            })}
            key={label}
            onClick={onToggle(label)}
          >
            <Paragraph
              className={classNames(styles.label, {
                [styles.opened]: opened === label,
              })}
              color="light"
              appearance="bold"
            >
              {label}
              <span className={styles.toggle}></span>
            </Paragraph>
            <div className={styles.items}>
              {opened === label &&
                items.map(({ title, url, target, caption }) => (
                  <PageLink
                    link={url}
                    target={target}
                    className={styles.item}
                    key={title}
                  >
                    <Paragraph
                      color="light"
                      appearance="bold"
                      className={styles.title}
                    >
                      {title}
                    </Paragraph>

                    <Paragraph
                      className={styles.caption}
                      color="light"
                      appearance="smaller"
                    >
                      {caption}
                    </Paragraph>
                  </PageLink>
                ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <CoursesExtraLink
          to="/programs/comparison"
          title={t("comparison_title")}
        />
        <span className={styles.divider} />
        <CoursesExtraLink to="/programs" title={t("all_programs_title")} />
      </div>
    </div>
  );
};
