export const groupItems = (items) => {
  const itemsCopy = [...items];

  const highlightedIndex = itemsCopy.findIndex((item) =>
    Boolean(item.highlight)
  );

  const [highlighted] = itemsCopy.splice(highlightedIndex, 1) ?? [];

  const uniqueLabels = [
    ...new Set(itemsCopy.map(({ courseLabel }) => courseLabel).filter(Boolean)),
  ];

  const groups = uniqueLabels.reduce((acc, label) => {
    acc.push({
      label,
      items: itemsCopy.filter(({ courseLabel }) => courseLabel === label),
    });
    return acc;
  }, []);

  return { groups, highlighted };
};
