import { useCallback, useState } from "react";

export const useToggle = () => {
  const [opened, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const onToggle = useCallback(() => setOpen((current) => !current), []);

  return { opened, onOpen, onClose, onToggle };
};
