import { logError } from "../logger";

export const dataLayerEvent = (formType, options = {}) => {
  if (typeof window !== "undefined") {
    try {
      window?.dataLayer?.push({
        event: "form_success",
        form_type: formType,
        ...options,
      });
    } catch (error) {
      logError(error, "Failed push event to dataLayer");
    }
  }
};
