export const mergeWithBanners = (
  feed,
  banners,
  positionBanner = "fixedPosition"
) => {
  banners.forEach((promoBanner) => {
    const index = promoBanner[positionBanner] - 1;

    feed.splice(index, 0, { ...promoBanner, type: "banner" });
  });

  return feed;
};

const defaultCourses = [];
const defaultEvents = [];

export const mergeWithUnsortedBlocks = (
  sortedFeed,
  courses = defaultCourses,
  events = defaultEvents
) => {
  courses?.forEach((course, index) => {
    const randomIndex = index * 3 + 2;

    sortedFeed.splice(randomIndex, 0, { ...course, type: "course" });
  });

  events?.forEach((event, index) => {
    const randomIndex = index * 4 + 3;

    sortedFeed.splice(randomIndex, 0, { ...event, type: "event" });
  });

  return sortedFeed;
};
