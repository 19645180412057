import classNames from "classnames";
import { useTranslation } from "features/translations";
import React from "react";
import { Action, Icon } from "shared/components";

import { useLazyRenderingState } from "./LazyRendering.hooks";
import styles from "./LazyRenderingButton.module.scss";

export const LazyRenderingButton = ({ appearance, buttonText }) => {
  const { t } = useTranslation();
  const { isCompleted, renderNext } = useLazyRenderingState();

  if (isCompleted) {
    return null;
  }
  return (
    <Action onClick={renderNext} className={styles.more}>
      <div className={styles.loader}>
        <div
          className={classNames(styles["loader-wrapper"], styles[appearance])}
        >
          {buttonText || t("lazyRendering")}
          <Icon className={styles.icon} name="loader" />
        </div>
      </div>
    </Action>
  );
};
