import classNames from "classnames";
import { useTranslation } from "features/translations";
import Link from "next/link";

import styles from "./InlineLanguages.module.scss";

export const InlineLanguages = ({ currentHref, locale, locales }) => {
  const { t } = useTranslation();
  return (
    <ul className={styles.list}>
      {locales.map((localeKey) => (
        <li
          key={localeKey}
          className={classNames(styles.item, {
            [styles.active]: localeKey === locale,
          })}
        >
          <Link href={currentHref} locale={localeKey}>
            <a>{t(`locale.${localeKey}`)}</a>
          </Link>
        </li>
      ))}
    </ul>
  );
};
