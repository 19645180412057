import Link from "next/link";

import { getLinkParams } from "./getLinkParams";

export const PageLink = ({
  children,
  directory,
  slug,
  link,
  className,
  target = "_self",
}) => {
  let linkOptions = "";
  if (slug) {
    linkOptions = getLinkParams(directory, slug);
  }

  if (link) {
    const absolute = link?.includes("https");
    if (absolute) {
      return (
        <a href={link} className={className} target={target} rel="noreferrer">
          {children}
        </a>
      );
    }
    return (
      <Link href={link}>
        <a className={className}>{children}</a>
      </Link>
    );
  }

  return (
    <Link href={linkOptions}>
      <a className={className}>{children}</a>
    </Link>
  );
};
