import { List } from "components/SocialSharing/List";
import { useCallback, useState } from "react";
import { Button } from "shared/components";
import { useOutsideClick } from "shared/hooks";

import styles from "./InteractiveItems.module.scss";

export const InteractiveItems = () => {
  const [active, setActive] = useState(false);

  const clickHandler = useCallback(() => setActive(true), []);
  const outsideClickHandler = useCallback(() => setActive(false), []);

  const ref = useOutsideClick(outsideClickHandler);

  return (
    <div className={styles.wrapper} ref={ref}>
      <Button
        onClick={clickHandler}
        icon="share"
        className={styles.button}
        appearance="outlined"
      />

      {active && <List className={styles.list} buttonClassName={styles.icon} />}
    </div>
  );
};
