import { Icon, PageLink, Paragraph } from "shared/components";

import styles from "./CoursesExtraLink.module.scss";

export const CoursesExtraLink = ({ title, description, to }) => (
  <PageLink link={to} className={styles.wrapper}>
    <div className={styles.link}>
      <span className={styles.text}>
        <Paragraph color="dark" appearance="bold" className={styles.title}>
          {title}
        </Paragraph>
        <Paragraph
          className={styles["caption-general"]}
          color="dark"
          appearance="smaller"
        >
          {description}
        </Paragraph>
      </span>
      <div className={styles.button}>
        <Icon name="arrow_right" className={styles.icon} />
      </div>
    </div>
  </PageLink>
);
