import { WIDGETS_REGEXES } from "./RichTextWidget.config";

export const extractChildData = (node) => {
  if (node.type !== "tag" || !Array.isArray(node.children)) {
    return null;
  }

  const data = node?.children?.[0]?.data ?? "";

  return data.length > 0 && isValidWidgetString(data) ? data : null;
};

export const extractElementData = (node) => {
  if (node.type !== "text") {
    return null;
  }

  const textData = node?.data ?? "";

  return textData.length > 0 && isValidWidgetString(textData) ? textData : null;
};

export const isValidWidgetString = (string) => {
  return WIDGETS_REGEXES.some((regex) => regex.test(string));
};
