import parse from "html-react-parser";
import { ErrorBoundary } from "shared/components";

import { RichTextContainer } from "./RichTextContainer";
import { extractChildData, extractElementData } from "./RichTextWidget.utils";
import { RichTextWidgetRenderer } from "./RichTextWidgetRenderer";

export const RichTextWidget = ({ value, className, color }) => {
  if (typeof value !== "string") {
    return null;
  }
  return (
    <RichTextContainer className={className} color={color}>
      {parse(value, {
        replace: (domNode) => {
          const widgetData =
            extractChildData(domNode) || extractElementData(domNode);

          if (widgetData !== null) {
            return (
              <ErrorBoundary>
                <RichTextWidgetRenderer rawData={widgetData} />
              </ErrorBoundary>
            );
          }

          return domNode;
        },
      })}
    </RichTextContainer>
  );
};
