import { useTranslation } from "features/translations";
import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  Heading3,
  Icon,
  ImageLite,
  PageLink,
  Paragraph,
  RenderIf,
} from "shared/components";
import { VIEW_MODES } from "shared/contexts/ViewMode";
import { formatDate } from "shared/utils";

import styles from "./HighlightedArticle.module.scss";

export const HighlightedArticle = ({ item }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const date = useMemo(() => {
    return formatDate(item?.date, locale);
  }, [item.date, locale]);

  return (
    <div className={styles.article}>
      <ImageLite image={item.previewImage} className={styles.image} />
      <div className={styles.content}>
        <Paragraph color="light" className={styles.date} appearance="bold">
          {date}
        </Paragraph>
        <div className={styles.card}>
          <div className={styles.text}>
            <Heading3 color="light" className={styles.title}>
              {item.title}
            </Heading3>
            <RenderIf viewMode={VIEW_MODES.DESKTOP}>
              <Paragraph color="light" className={styles.description}>
                {item.description}
              </Paragraph>
            </RenderIf>
          </div>
          <PageLink slug={item.slug} directory="news" className={styles.link}>
            <span className={styles.arrow}>
              <Icon className={styles.icon} name="large_arrow_right" />
            </span>
            <Paragraph color="light" appearance="bold">
              {t("more")}
            </Paragraph>
          </PageLink>
        </div>
      </div>
    </div>
  );
};
