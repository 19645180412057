import { useMemo } from "react";
import { Heading7, ImageLite, PageLink, Paragraph } from "shared/components";
import { useViewMode } from "shared/contexts/ViewMode";

import { useCollectedTeachers } from "../TeacherList.hook.js";
import styles from "./TeacherList.module.scss";

export const TeacherList = ({ payload, timing = null }) => {
  const { isDesktop } = useViewMode();

  const { teachers: rawTeachers } = useCollectedTeachers({
    payload,
  });

  const timingChunks = timing?.replace(/[{}]/g, "").split(",");
  const teachers = useMemo(() => {
    if (timing) {
      return rawTeachers?.map((item, i) => ({
        ...item,
        timing: timingChunks[i] ?? "",
      }));
    }
    return rawTeachers;
  }, [rawTeachers, timing, timingChunks]);

  if (!rawTeachers) {
    return null;
  }

  return (
    <div className={styles.teachers}>
      {teachers?.map((teacher) => (
        <PageLink
          className={styles.teacher}
          directory="teachers"
          slug={teacher.slug}
          key={teacher.id}
        >
          {isDesktop && teacher.timing && (
            <Paragraph className={styles.timing}>{teacher.timing}</Paragraph>
          )}
          <ImageLite image={teacher.photo} className={styles.image} />
          <div className={styles.text}>
            <Heading7 className={styles.title} color="light">
              {teacher.title}
            </Heading7>
            <Paragraph className={styles.description} color="grey">
              {teacher.description}
            </Paragraph>
          </div>
        </PageLink>
      ))}
    </div>
  );
};
