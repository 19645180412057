import { registerWidget, WIDGETS_REGISTRY } from "features/widgets";

import { DynamicFooter001 } from "./Footer001";
import { DynamicFooter002 } from "./Footer002";
import { DynamicFooter003 } from "./Footer003";
import { DynamicFooter005 } from "./Footer005";

const registerContentWidget = registerWidget(WIDGETS_REGISTRY.FOOTER);

export const footerWidgetsRegistry = {
  ...registerContentWidget("f001", DynamicFooter001),
  ...registerContentWidget("f002", DynamicFooter002),
  ...registerContentWidget("f003", DynamicFooter003),
  ...registerContentWidget("f005", DynamicFooter005),
};
