import { useEffect } from "react";

export const useWindowScrollListener = (callback, listenToScroll) => {
  useEffect(() => {
    if (listenToScroll) {
      window.addEventListener("scroll", callback, true);
    } else {
      window.removeEventListener("scroll", callback, true);
    }

    return () => {
      // force delete the listener after the hook is destroyed
      window.removeEventListener("scroll", callback, true);
    };
  }, [callback, listenToScroll]);

  return null;
};
