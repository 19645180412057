import classNames from "classnames";
import { useTranslation } from "features/translations";
import Link from "next/link";
import { useCallback, useState } from "react";
import { Icon } from "shared/components";
import { useViewMode } from "shared/contexts/ViewMode";
import { useOutsideClick, useWindowScrollListener } from "shared/hooks";

import styles from "./InteractiveLanguages.module.scss";

export const InteractiveLanguages = ({ currentHref, locale, locales }) => {
  const [opened, setOpened] = useState(false);
  const { isMobile, isDesktop } = useViewMode();
  const { t } = useTranslation();

  const closeList = useCallback(() => setOpened(false), []);
  const openList = useCallback(() => setOpened(true), []);
  const toggleList = useCallback(() => setOpened((current) => !current), []);

  const events = {
    ...(isMobile && { onClick: toggleList }),
    ...(isDesktop && {
      onMouseOver: openList,
      onMouseLeave: closeList,
    }),
  };

  /**
   * close list if click outside the language selector element was happened
   */
  const ref = useOutsideClick(closeList);
  /**
   * close list if scroll was started
   */
  useWindowScrollListener(closeList, opened);

  const availableLocales = locales.filter((localeKey) => localeKey !== locale);

  return (
    <div className={styles.wrapper} {...events} ref={ref}>
      <p
        className={classNames(styles.item, {
          [styles.opened]: opened,
        })}
      >
        {t(`locale.${locale}`)}
        <Icon
          className={styles.icon}
          name="dropdown"
          theme={opened ? "accent" : "default"}
        />
      </p>

      {opened && (
        <ul className={styles.list}>
          {availableLocales.map((localeKey) => (
            <li key={localeKey} className={styles.item}>
              <Link href={currentHref} locale={localeKey}>
                <a>{t(`locale.${localeKey}`)}</a>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
