import classNames from "classnames";

import { Typography } from "../Typography";
import styles from "./MenuItem.module.scss";

export const MenuItem = ({ className, color, children, href }) => (
  <Typography
    as="a"
    uppercase
    color={color}
    className={classNames(className, styles.element)}
    attributes={{
      href,
    }}
  >
    {children}
  </Typography>
);
