import classNames from "classnames";
import { RichText } from "components/RichTextWidget";
import { Heading6, Paragraph } from "shared/components";

import styles from "./OrderedList.module.scss";

export const OrderedList = ({ items, appearance, className }) => {
  const formatCounter = (number) => (number > 9 ? number : `0${number}`);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <ol className={styles.list}>
        {items.map((item, index) => (
          <li className={styles["list-item"]} key={item.id}>
            <Heading6 color="brand" as="span" plain={true}>
              {formatCounter(index + 1)}
            </Heading6>
            <Paragraph
              as="p"
              className={classNames(styles.title, styles[appearance])}
            >
              {item.title}
            </Paragraph>
            {item.description && (
              <RichText
                color="dark"
                value={item.description}
                className={styles.description}
              />
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};
