import classNames from "classnames";
import { Navigation } from "components/Navigation";
import { SignInButton } from "components/SignInButton";
import { SocialSharing } from "components/SocialSharing";
import Router from "next/router";
import { useEffect } from "react";
import { Button, Portal } from "shared/components";
import { useToggle } from "shared/hooks";

import styles from "./BurgerMenu.module.scss";

export const BurgerMenu = () => {
  const { opened, onToggle, onClose } = useToggle();

  useEffect(() => {
    Router.events.on("routeChangeComplete", onClose);

    return () => {
      Router.events.off("routeChangeComplete", onClose);
    };
  }, [onClose]);

  return (
    <>
      <Button
        onClick={onToggle}
        className={classNames({
          [styles.active]: opened,
        })}
        icon={opened ? "close" : "menu"}
        appearance={"default"}
      />
      {opened && (
        <Portal className={styles.container}>
          <div className={styles.content}>
            <Navigation />
            <SignInButton noIcon className={styles.signin} theme="light" />
            <SocialSharing inline />
          </div>
        </Portal>
      )}
    </>
  );
};
