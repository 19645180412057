import { useState } from "react";

export const ImageLite = ({ image, className, fallback }) => {
  const [failed, setFailed] = useState(false);
  const alternativeText = image?.alternativeText ?? "";
  const imageUrl = image?.url ?? "";

  const onFail = () => {
    setFailed(true);
  };

  if (failed || !image) {
    return <>{fallback || null}</>;
  }

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      onError={onFail}
      className={className}
      src={imageUrl}
      alt={alternativeText}
    />
  );
};
