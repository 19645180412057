import classNames from "classnames";
import { PageLink, Paragraph } from "shared/components";

import styles from "./Tags.module.scss";

export const TagItem = ({ tag, activeTag }) => {
  return (
    <PageLink slug={tag.slug} directory="newsCategory" className={styles.link}>
      <Paragraph
        as="span"
        color="grey"
        className={classNames(styles.tag, {
          [styles.active]: activeTag,
        })}
        uppercase
      >
        {tag.title}
      </Paragraph>
    </PageLink>
  );
};
