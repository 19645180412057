import { CoursesMenu } from "components/Navigation/CoursesMenu";
import { DropdownLinks } from "components/Navigation/DropdownLinks";
import Router from "next/router";
import { Fragment, useEffect, useRef } from "react";
import { PageLink, Paragraph, Portal } from "shared/components";
import { useToggle } from "shared/hooks";

import styles from "./NavigationItem.module.scss";

export const NavigationItem = ({
  template,
  title,
  url,
  target,
  items,
  caption,
}) => {
  const ref = useRef(null);
  const { opened, onOpen, onClose } = useToggle();

  const dynamicStyles = {
    left:
      template !== "courses"
        ? ref?.current?.getBoundingClientRect()?.left ?? "auto"
        : 0,
  };

  useEffect(() => {
    Router.events.on("routeChangeComplete", onClose);

    return () => {
      Router.events.off("routeChangeComplete", onClose);
    };
  }, [onClose]);

  return (
    <Fragment>
      <div
        className={styles.item}
        onMouseOver={onOpen}
        ref={ref}
        onMouseLeave={onClose}
      >
        <PageLink link={url} target={target} className={styles.link}>
          <Paragraph
            className={styles.title}
            color="dark"
            appearance="smaller"
            uppercase
          >
            {title}
          </Paragraph>
        </PageLink>
        {opened && items.length > 0 && (
          <Portal>
            <div className={styles.overlay}>
              <div
                className={styles.dropdown}
                style={dynamicStyles}
                onMouseOver={onOpen}
                onMouseLeave={onClose}
              >
                {template === "courses" ? (
                  <CoursesMenu items={items} caption={caption} />
                ) : (
                  <DropdownLinks items={items} />
                )}
              </div>
            </div>
          </Portal>
        )}
      </div>
    </Fragment>
  );
};
