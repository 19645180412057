export const addBreak = (value) => {
  if ([...value.matchAll(/{{break}}/g)].length > 0) {
    return value.replaceAll("{{break}}", "<br/>");
  }

  return value;
};

export const addBold = (value) => {
  if ([...value.matchAll(/{{style:bold}}(.*?){{\/style:bold}}/g)].length > 0) {
    return value
      .replaceAll("{{style:bold}}", "<strong>")
      .replaceAll("{{/style:bold}}", "</strong>");
  }

  return value;
};
