import classNames from "classnames";
import { Container } from "shared/components";

import styles from "./FooterWidgetWrapper.module.scss";

// fill: light, grey
export const FooterWidgetWrapper = ({ children, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <Container>{children}</Container>
  </div>
);
