import { useRouter } from "next/router";
import { useMemo } from "react";
import { ImageLite, PageLink, Paragraph } from "shared/components";
import { formatDate, truncateText } from "shared/utils";

import styles from "./Article.module.scss";

const DEFAULT_VISIBLE_LETTERS = 100;

export const Article = ({ item, className }) => {
  const { locale } = useRouter();

  const date = useMemo(() => {
    return formatDate(item?.date, locale);
  }, [item.date, locale]);

  const truncatedTitle = useMemo(() => {
    const title = item?.title ?? "";
    return truncateText(title, DEFAULT_VISIBLE_LETTERS);
  }, [item?.title]);

  return (
    <div className={className}>
      <div className={styles.article}>
        <div className={styles.text}>
          <PageLink slug={item.slug} directory="news" className={styles.block}>
            <Paragraph color="dark" className={styles.title} appearance="bold">
              {truncatedTitle}
            </Paragraph>
          </PageLink>
          <div className={styles.date}>
            <Paragraph color="grey">{date}</Paragraph>
          </div>
        </div>
        <div className={styles.media}>
          <ImageLite image={item.previewImage} className={styles.image} />
        </div>
      </div>
    </div>
  );
};
