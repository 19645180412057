import { registerWidget, WIDGETS_REGISTRY } from "features/widgets";

import { DynamicLayoutWidget012 } from "./LayoutWidget012";
import { DynamicLayoutWidget013 } from "./LayoutWidget013";
import { DynamicLayoutWidget015 } from "./LayoutWidget015";
import { DynamicLayoutWidget031 } from "./LayoutWidget031";
import { DynamicLayoutWidget035 } from "./LayoutWidget035";
import { DynamicLayoutWidget066 } from "./LayoutWidget066";
import { DynamicLayoutWidget079 } from "./LayoutWidget079";
import { DynamicLayoutWidget081 } from "./LayoutWidget081";
import { DynamicLayoutWidget082 } from "./LayoutWidget082";
import { DynamicLayoutWidget086 } from "./LayoutWidget086";

const registerContentWidget = registerWidget(WIDGETS_REGISTRY["LAYOUT-WIDGET"]);

export const layoutWidgetsRegistry = {
  ...registerContentWidget("l012", DynamicLayoutWidget012),
  ...registerContentWidget("l013", DynamicLayoutWidget013),
  ...registerContentWidget("l015", DynamicLayoutWidget015),
  ...registerContentWidget("l031", DynamicLayoutWidget031),
  ...registerContentWidget("l035", DynamicLayoutWidget035),
  ...registerContentWidget("l066", DynamicLayoutWidget066),
  ...registerContentWidget("l079", DynamicLayoutWidget079),
  ...registerContentWidget("l081", DynamicLayoutWidget081),
  ...registerContentWidget("l082", DynamicLayoutWidget082),
  ...registerContentWidget("l086", DynamicLayoutWidget086),
};
