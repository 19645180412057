import { Heading4, Icon, PageLink } from "shared/components";

import styles from "./HighlightedCourse.module.scss";

export const HighlightedCourse = ({ data }) => {
  return (
    <PageLink link={data.url} target={data?.target} className={styles.link}>
      <Heading4
        color="dark"
        appearance="bold"
        uppercase
        className={styles.title}
      >
        {data.title}
      </Heading4>

      <div className={styles.button}>
        <Icon name="arrow_right" className={styles.icon} />
      </div>
    </PageLink>
  );
};
