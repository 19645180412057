import React, { createContext, useCallback, useMemo, useState } from "react";

export const LazyRenderingContext = createContext({});

export const LazyRenderingProvider = ({
  children,
  size,
  initialLimit,
  incrementBy,
}) => {
  const [limit, setLimit] = useState(initialLimit);

  const isCompleted = useMemo(() => size < limit, [size, limit]);

  const renderNext = useCallback(() => {
    if (!isCompleted) {
      setLimit((limit) => limit + incrementBy);
    }
  }, [isCompleted, incrementBy]);

  const resetState = useCallback(() => {
    setLimit(initialLimit);
  }, [initialLimit]);

  const value = {
    limit,
    renderNext,
    isCompleted,
    resetState,
  };

  return (
    <LazyRenderingContext.Provider value={value}>
      {children}
    </LazyRenderingContext.Provider>
  );
};
