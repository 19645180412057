import classNames from "classnames";
import { useTranslation } from "features/translations";

import { Icon } from "../Icon";
import { Typography } from "../Typography";
import styles from "./DownloadFile.module.scss";

export const DownloadFile = ({
  as = "span",
  file,
  icon = "download",
  label,
  color = "dark",
  className,
}) => {
  const { t } = useTranslation();
  const href = file?.url ?? "";

  if (!href) {
    return null;
  }
  return (
    <a href={href} className={styles.download} target="_blank" rel="noreferrer">
      <Icon className={styles.icon} name={icon} />
      <Typography
        className={classNames(className, styles.element)}
        color={color}
        as={as}
      >
        {label || t("download")}
      </Typography>
    </a>
  );
};
