import { useRouter } from "next/router";
import { useEffect } from "react";

export const Redirect = ({ path }) => {
  const { push } = useRouter();

  useEffect(() => {
    push(path);
  }, [path, push]);

  return null;
};
