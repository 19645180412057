import { useTranslation } from "features/translations";
import { Fragment, useCallback, useLayoutEffect, useState } from "react";
import { Button, PageLink, Paragraph, Portal } from "shared/components";

import { CoursesMobileMenu } from "../CoursesMobileMenu";
import styles from "./NavigationMobileItem.module.scss";

export const NavigationMobileItem = ({
  template,
  title,
  url,
  target,
  items,
}) => {
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const onToggle = useCallback(() => {
    setActive((current) => !current);
  }, []);

  useLayoutEffect(() => {
    document.body.classList.add("is-frozen");

    return () => {
      document.body.classList.remove("is-frozen");
    };
  });

  return (
    <Fragment>
      {items.length > 0 ? (
        <div className={styles.item} onClick={onToggle}>
          <Paragraph className={styles.title} color="light" appearance="bold">
            {title}
          </Paragraph>
          <Button className={styles.arrow} icon="arrow_right"></Button>
        </div>
      ) : (
        <PageLink link={url} target={target} className={styles.link}>
          <Paragraph className={styles.title} color="light" appearance="bold">
            {title}
          </Paragraph>
        </PageLink>
      )}

      {active && (
        <Portal className={styles.container}>
          <div className={styles.content}>
            <div className={styles["button-back"]}>
              <Button
                className={styles.arrow}
                icon="back"
                onClick={onToggle}
              ></Button>
              <Paragraph className={styles.back} appearance="smaller">
                {t("back")}
              </Paragraph>
            </div>

            {template === "courses" ? (
              <CoursesMobileMenu items={items} />
            ) : (
              items.map(({ title, url }) => (
                <PageLink
                  link={url}
                  className={styles["item-link"]}
                  key={title}
                >
                  <Paragraph
                    className={styles["item-title"]}
                    color="light"
                    appearance="bold"
                  >
                    {title}
                  </Paragraph>
                </PageLink>
              ))
            )}
          </div>
        </Portal>
      )}
    </Fragment>
  );
};
