import classNames from "classnames";
import { Icon } from "shared/components/Icon";

import styles from "./Button.module.scss";

export const Button = ({
  as: HtmlElement = "button",
  htmlAttributes = {},
  icon = "",
  children = null,
  appearance = "default", // Enum: default, outlined
  onClick,
  className,
  theme = "dark", // Enum: dark, light
  hover = "highlight", // Enum: highlight, reverse
}) => {
  const elementProps = {
    ...htmlAttributes,
    ...(onClick && { onClick }),
  };

  return (
    <HtmlElement
      {...elementProps}
      className={classNames(
        styles.button,
        styles[hover],
        styles[theme],
        styles[appearance],
        className,
        {
          [styles["no-icon"]]: !icon,
          [styles["no-text"]]: !children,
        }
      )}
    >
      {icon && <Icon className={styles.icon} name={icon} />}
      {children && <span className={styles.text}>{children}</span>}
    </HtmlElement>
  );
};
