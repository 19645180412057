import classNames from "classnames";
import { useLayoutEffect } from "react";
import { createPortal } from "react-dom";

import styles from "./Portal.module.scss";

export const Portal = ({ className = "", children }) => {
  const rootElement = document.querySelector("#portals-root");

  useLayoutEffect(() => {
    document.body.classList.add("is-frozen");

    return () => {
      document.body.classList.remove("is-frozen");
    };
  });

  return createPortal(
    <div className={classNames(styles.portal, className)}>{children}</div>,
    rootElement
  );
};
