import { useTranslation } from "features/translations";
import { Paragraph } from "shared/components";

import styles from "./FormInfo.module.scss";

export const FormInfo = ({ title, date }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.info}>
      <div className={styles.item}>
        <Paragraph className={styles["info-label"]} color="grey" uppercase>
          {t("title_event")}
        </Paragraph>
        <Paragraph
          className={styles["info-text"]}
          color="dark"
          appearance="bold"
        >
          {title}
        </Paragraph>
      </div>
      <div className={styles.item}>
        <Paragraph className={styles["info-label"]} color="grey" uppercase>
          {t("date_event")}
        </Paragraph>
        <Paragraph
          className={styles["info-text"]}
          color="dark"
          appearance="bold"
        >
          {date}
        </Paragraph>
      </div>
    </div>
  );
};
