import { createContext } from "react";

export const defaultValue = {
  data: {
    seo: {},
    content: [],
    footer: [],
    hero: [],
  },
  collectedData: {},
  templatedData: {
    hero: [],
    content: [],
  },
};

export const PageDataContext = createContext(defaultValue);
