import { useTranslation } from "features/translations";
import { Icon, ImageLite, PageLink, Paragraph } from "shared/components";
import { useViewMode } from "shared/contexts/ViewMode";
import { useOutsideClick, useToggle } from "shared/hooks";

import { useCollectedTeachers } from "../TeacherList.hook.js";
import styles from "./TeacherLink.module.scss";

export const TeacherLink = ({ payload }) => {
  const { t } = useTranslation();
  const { isDesktop } = useViewMode();
  const { teachers } = useCollectedTeachers({
    payload,
  });

  const { opened, onOpen, onClose } = useToggle();

  const events = {
    ...(isDesktop && {
      onMouseOver: onOpen,
      onMouseLeave: onClose,
    }),
  };

  const ref = useOutsideClick(onClose);

  if (teachers) {
    const [teacher] = teachers;
    return (
      <div className={styles.teacher} {...events} ref={ref}>
        <PageLink directory="teachers" slug={teacher.slug}>
          <Paragraph className={styles.name} color="grey">
            {teacher.title}
          </Paragraph>
        </PageLink>
        {opened && (
          <PageLink
            className={styles.link}
            directory="teachers"
            slug={teacher.slug}
          >
            <div className={styles.tooltip}>
              <ImageLite image={teacher.photo} className={styles.image} />
              <Paragraph className={styles.title}>{teacher.title}</Paragraph>
              <Paragraph className={styles.description}>
                {teacher.description}
              </Paragraph>
              <span className={styles.arrow}>
                <Paragraph>{t("read_more")}</Paragraph>
                <Icon className={styles.icon} name="arrow_right" />
              </span>
            </div>
          </PageLink>
        )}
      </div>
    );
  }
};
