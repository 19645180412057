import { useMemo } from "react";
import { useViewMode } from "shared/contexts/ViewMode";

import { FooterDesktop } from "./FooterDesktop";
import { FooterMobile } from "./FooterMobile";
import { FooterWidgets } from "./FooterWidgets";

export const Footer = () => {
  const { isDesktop } = useViewMode();

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <FooterWidgets />

      {isDesktop ? (
        <FooterDesktop year={currentYear} />
      ) : (
        <FooterMobile year={currentYear} />
      )}
    </>
  );
};
