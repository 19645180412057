import { pipe } from "shared/utils";

import { addBold, addBreak } from "./RichString.modifiers";

const richStringModifier = pipe(addBreak, addBold);

export const RichString = ({ value }) => {
  try {
    const result = richStringModifier(value);
    return <span dangerouslySetInnerHTML={{ __html: result }} />;
  } catch (e) {
    return value;
  }
};
