import classNames from "classnames";
import { useTranslation } from "features/translations";
import { Heading5, Icon } from "shared/components";

import styles from "./StatusPopup.module.scss";

export const StatusPopup = ({ status, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.wrapper, styles[className])}>
      <div className={styles.blocks}>
        <Heading5 color="dark" className={styles.title}>
          {t(status)}
        </Heading5>
        <div className={styles.circles}>
          <div className={styles.circle}></div>
          <Icon
            name={className === "successful" ? "done" : " close"}
            className={styles.icon}
          />
        </div>
      </div>
    </div>
  );
};
