import classNames from "classnames";
import { Container, Heading6, ImageLite, Paragraph } from "shared/components";

import styles from "./Poster.module.scss";

export const Poster = ({ appearance, title, image }) => {
  const TitleElement = appearance === "default" ? Paragraph : Heading6;
  const titleProps = appearance === "default" ? { appearance: "bold" } : {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <Container className={classNames(styles.content, styles[appearance])}>
          <TitleElement className={styles.title} color="light" {...titleProps}>
            {title}
          </TitleElement>
        </Container>

        <ImageLite image={image} className={styles.image} />
      </div>
    </div>
  );
};
