const validators = {
  required: (value) => Boolean(value),
  pattern: (value, regex) => new RegExp(regex).test(value),
};

export const validate = (data, config) =>
  config.reduce((errorsAcc, { name, validator }) => {
    const fieldValue = data[name];
    const fieldErrors = validateOne(name, fieldValue, validator);

    return { ...errorsAcc, ...fieldErrors };
  }, {});

export const validateOne = (nameOne, dataOne, configOne) => {
  const validatorsEntries = Object.entries(configOne);

  const fieldErrors = validatorsEntries.reduce(
    (acc, [validatorKey, validatorValue]) => {
      const result = validators[validatorKey](dataOne, validatorValue);
      if (!result) {
        return { ...acc, [validatorKey]: true };
      }
      return acc;
    },
    {}
  );

  if (Object.keys(fieldErrors).length > 0) {
    return { [nameOne]: fieldErrors };
  }

  return { [nameOne]: null };
};
