import { getCmsUrl } from "features/cms";
import { useCallback, useState } from "react";

export const useFormSubmit = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);

  const onResetFormSubmit = useCallback(() => {
    setSuccess(false);
    setPending(false);
  }, []);

  const onSubmit = useCallback(({ type, data, metaData }) => {
    setPending(true);
    fetch(getCmsUrl("/api/form-request"), {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        data: {
          email: data.email,
          ...(metaData && { metaData }),
          type: type,
          formData: data,
        },
      }),
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  return {
    error,
    success,
    pending,
    onResetFormSubmit,
    onSubmit,
  };
};
