import classNames from "classnames";
import { FormContent } from "components/Forms";
import { useTranslation } from "features/translations";
import { useContext } from "react";
import { MaskedInput } from "react-hook-mask";

import styles from "./InputTel.module.scss";

const mask = "+38(0XX)XXX-XX-XX";
const maskGenerator = {
  rules: new Map([["X", /\d/]]),
  generateMask: () => mask,
};

export const InputTel = ({ type, name, placeholder }) => {
  const { t } = useTranslation();
  const { onChange, onValidate, getInputValue, getInputError } =
    useContext(FormContent);

  const value = getInputValue(name);
  const errors = getInputError(name);

  const updateHandler = (e) => {
    if (e) {
      onChange({
        ...e,
        target: {
          ...e.target,
          name: name,
          value: `380${e}`,
        },
      });
    }
  };

  return (
    <div
      className={classNames(
        styles["input-type"],
        styles[`input-type--${type}`]
      )}
    >
      <MaskedInput
        type={type}
        name={name}
        placeholder={t(placeholder)}
        className={classNames(styles.input, {
          [styles["has-error"]]: errors?.required || errors?.pattern,
        })}
        value={value}
        onChange={updateHandler}
        onBlur={onValidate}
        maskGenerator={maskGenerator}
      />
      {errors?.required && (
        <span className={styles.error}>{t("error_required")}</span>
      )}
    </div>
  );
};
