import { StatusPopup } from "components/StatusPopup";
import { useContext } from "react";
import { ModalWrapper } from "shared/components";

import { FormContent } from "../Form.Provider";

export const FormSubmitStatus = () => {
  const { success, error, statusErrorText, statusSuccessfulText, onResetForm } =
    useContext(FormContent);

  const handleClose = () => {
    onResetForm();
  };

  return (
    <>
      {success && (
        <ModalWrapper onCloseModal={handleClose}>
          <StatusPopup status={statusSuccessfulText} className="successful" />
        </ModalWrapper>
      )}
      {error && (
        <ModalWrapper onCloseModal={handleClose}>
          <StatusPopup status={statusErrorText} className="error" />
        </ModalWrapper>
      )}
    </>
  );
};
