import classNames from "classnames";
import { FormContent } from "components/Forms";
import { useTranslation } from "features/translations";
import { useContext } from "react";

import styles from "./InputCheckbox.module.scss";

export const InputCheckbox = ({ type, name, label }) => {
  const { t } = useTranslation();
  const { onChange, getInputValue, getInputError } = useContext(FormContent);

  const value = getInputValue(name);
  const errors = getInputError(name);
  const updateHandler = (e) => {
    onChange({
      ...e,
      target: {
        ...e.target,
        name: name,
        value: e.target.checked,
      },
    });
  };

  return (
    <div
      className={classNames(
        styles["input-type"],
        styles[`input-type--${type}`]
      )}
    >
      <label className={styles.label}>
        <input
          type={type}
          name={name}
          className={styles.checkbox}
          checked={value}
          onChange={updateHandler}
        />
        <span
          className={classNames(styles.checkmark, {
            [styles["checkmark--required"]]: errors?.required,
          })}
        />
        {t(label)}
      </label>
    </div>
  );
};
