import Link from "next/link";
import { useGlobalState } from "shared/contexts/Global";

import styles from "./SiteLogo.module.scss";

export const SiteLogo = ({ type = "header" }) => {
  const { logoHeader, logoFooter } = useGlobalState();
  const headerUrl = logoHeader?.url ?? "";
  const footerUrl = logoFooter?.url ?? "";

  const src = type === "header" ? headerUrl : footerUrl;

  return (
    <Link href="/">
      <a className={styles.wrapper}>
        <img src={src} className={styles.image} alt=" " />
      </a>
    </Link>
  );
};
