import classNames from "classnames";
import { useMenusState } from "shared/contexts/Menus";
import { useViewMode } from "shared/contexts/ViewMode";

import styles from "./Navigation.module.scss";
import { NavigationItem } from "./NavigationItem";
import { NavigationMobileItem } from "./NavigationMobileItem";

export const Navigation = () => {
  const { headerData } = useMenusState();
  const { isDesktop } = useViewMode();

  return (
    <nav
      className={classNames(styles.nav, { [styles["nav-mobile"]]: !isDesktop })}
    >
      {headerData.map(({ id, ...rest }) =>
        isDesktop ? (
          <NavigationItem key={id} {...rest} />
        ) : (
          <NavigationMobileItem key={id} {...rest} />
        )
      )}
    </nav>
  );
};
