import { mergeWithUnsortedBlocks } from "./mergeWithBanners";

const defaultArticle = [];

export const buildFeed = ({ articles = defaultArticle }) => {
  return [...articles.map((article) => ({ ...article, type: "article" }))];
};

export const buildSortedFeed = ({ articles, events, courses }) => {
  const sortedFeed = buildFeed({ articles }).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return mergeWithUnsortedBlocks(sortedFeed, courses, events);
};
