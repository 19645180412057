import { Button } from "shared/components";
import { useGlobalState } from "shared/contexts/Global";

export const CallButton = ({ noTitle = false, theme, hover }) => {
  const { phoneNumber } = useGlobalState();

  return (
    <Button
      as="a"
      htmlAttributes={{ href: `tel:${phoneNumber}` }}
      icon="tel"
      theme={theme}
      hover={hover}
      appearance="outlined"
    >
      {!noTitle ? phoneNumber : null}
    </Button>
  );
};
