import { registerWidget, WIDGETS_REGISTRY } from "features/widgets";

import { DynamicHero001 } from "./Hero001";
import { DynamicHero004 } from "./Hero004";
import { DynamicHero014 } from "./Hero014";
import { DynamicHero016 } from "./Hero016";
import { DynamicHero028 } from "./Hero028";
import { DynamicHero029 } from "./Hero029";
import { DynamicHero030 } from "./Hero030";
import { DynamicHero031 } from "./Hero031";
import { DynamicHero032 } from "./Hero032";
import { DynamicHero033 } from "./Hero033";
import { DynamicHero036 } from "./Hero036";
import { DynamicHero037 } from "./Hero037";
import { DynamicHero048 } from "./Hero048";
import { DynamicHero063 } from "./Hero063";
import { DynamicHero073 } from "./Hero073";

const registerContentWidget = registerWidget(WIDGETS_REGISTRY.HERO);

export const heroWidgetsRegistry = {
  ...registerContentWidget("001", DynamicHero001),
  ...registerContentWidget("004", DynamicHero004),
  ...registerContentWidget("014", DynamicHero014),
  ...registerContentWidget("016", DynamicHero016),
  ...registerContentWidget("028", DynamicHero028),
  ...registerContentWidget("029", DynamicHero029),
  ...registerContentWidget("030", DynamicHero030),
  ...registerContentWidget("031", DynamicHero031),
  ...registerContentWidget("032", DynamicHero032),
  ...registerContentWidget("033", DynamicHero033),
  ...registerContentWidget("036", DynamicHero036),
  ...registerContentWidget("037", DynamicHero037),
  ...registerContentWidget("048", DynamicHero048),
  ...registerContentWidget("063", DynamicHero063),
  ...registerContentWidget("073", DynamicHero073),
};
