import { List } from "components/SocialSharing/List";

import styles from "./InlineItems.module.scss";

export const InlineItems = ({
  buttonClassName = styles.button,
  className = styles.list,
  appearance = "light",
  title,
}) => (
  <div>
    {title && <span className={styles.title}>{title}</span>}
    <List
      className={className}
      buttonClassName={buttonClassName}
      appearance={appearance}
    />
  </div>
);
