import { createContext, useCallback, useMemo, useState } from "react";

export const TabsContext = createContext({});

export const DEFAULT_TAB_ID = "default_tab_all";

export const TabsProvider = ({ children, items, defaultTab, tabsEnabled }) => {
  const [rawItems, setRawItems] = useState(items);
  const initialTab = defaultTab ? DEFAULT_TAB_ID : rawItems?.[0];
  const [activeTab, setActiveTab] = useState(initialTab);

  const setActiveTabHandler = useCallback(
    (nextTab) => setActiveTab(nextTab),
    []
  );

  const resetActiveTab = useCallback(
    () => setActiveTab(initialTab),
    [initialTab]
  );

  const setItems = useCallback((nextItems) => setRawItems(nextItems), []);

  const isDefaultTab = useMemo(() => activeTab === DEFAULT_TAB_ID, [activeTab]);

  const tabs = useMemo(() => {
    return defaultTab ? [DEFAULT_TAB_ID, ...rawItems] : rawItems;
  }, [rawItems, defaultTab]);

  const activeTabIndex = useMemo(
    () => tabs.findIndex((tab) => tab === activeTab),
    [tabs, activeTab]
  );

  const value = {
    tabs,
    activeTab,
    activeTabIndex,
    setActiveTab: setActiveTabHandler,
    resetActiveTab: resetActiveTab,
    setItems: setItems,
    isDefaultTab,
    tabsEnabled,
  };

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
