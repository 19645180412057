import classNames from "classnames";
import { dataLayerEvent } from "features/dataLayer";
import { useTranslation } from "features/translations";
import { useContext, useEffect } from "react";
import {
  Action,
  Icon,
  InputType as InputItem,
  Paragraph,
} from "shared/components";

import { FormContent } from "../Form.Provider";
import { FormSubmitStatus } from "../FormSubmitStatus";
import styles from "./FormSubscription.module.scss";

export const FormSubscription = ({ className }) => {
  const { t } = useTranslation();
  const {
    data,
    type,
    title,
    fields,
    canBeSubmitted,
    pending,
    onHandle,
    onSubmit,
  } = useContext(FormContent);

  useEffect(() => {
    if (canBeSubmitted) {
      onSubmit({ type, data });
      dataLayerEvent("subscription");
    }
  }, [canBeSubmitted, data, onSubmit, type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onHandle();
  };

  return (
    <>
      <div className={classNames(styles.wrapper, styles[className])}>
        <div className={styles.blocks}>
          <Paragraph className={classNames(styles.title, className)}>
            {t(title)}
          </Paragraph>
          <div className={styles.subscribe}>
            <form onSubmit={handleSubmit}>
              <div className={styles.form}>
                <div className={styles["input-group"]}>
                  {fields.map((input) => {
                    return <InputItem key={input.id} {...input} />;
                  })}
                </div>
                <Action disabled={pending} className={styles.circles}>
                  <span className={styles.arrow}>
                    <Icon className={styles.icon} name="arrow_right" />
                  </span>
                </Action>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FormSubmitStatus />
    </>
  );
};
