import { Button } from "shared/components";
import { useGlobalState } from "shared/contexts/Global";

export const List = ({ className, buttonClassName, appearance = "dark" }) => {
  const { socialNetworks } = useGlobalState();

  return (
    <nav className={className}>
      {socialNetworks.map(({ title, href, icon }) => (
        <Button
          key={`${title}_${href}_${icon}`}
          as="a"
          htmlAttributes={{ href, title, target: "_blank" }}
          icon={icon}
          className={buttonClassName}
          appearance="outlined"
          theme={appearance}
          hover={appearance === "dark" ? "fill" : "reverse"}
        />
      ))}
    </nav>
  );
};
