import classNames from "classnames";

import styles from "./YoutubeVideo.module.scss";

export const YoutubeVideo = ({ className, id }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <iframe
        className={styles.player}
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};
