import classNames from "classnames";

import styles from "./Action.module.scss";

export const Action = ({ children, className, onClick, disabled = false }) => {
  return (
    <button
      disabled={disabled}
      className={classNames(styles.wrapper, className)}
      onClick={onClick}
      tabIndex="-1"
    >
      {children}
    </button>
  );
};
