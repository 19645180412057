import classNames from "classnames";

import { Typography } from "../Typography";
import styles from "./Paragraph.module.scss";

// type appearance = 'bold'|'bigger'|'smaller'
export const Paragraph = ({
  as = "p",
  className,
  color,
  appearance,
  uppercase,
  children,
  plain = false,
}) => (
  <Typography
    className={classNames(
      className,
      styles.element,
      styles[`appearance-${appearance}`]
    )}
    color={color}
    uppercase={uppercase}
    as={as}
    plain={plain}
  >
    {children}
  </Typography>
);
