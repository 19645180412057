import { useViewMode } from "shared/contexts/ViewMode";

import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export const Header = () => {
  const { isMobile } = useViewMode();

  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};
