import classNames from "classnames";
import { useTranslation } from "features/translations";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Paragraph } from "shared/components";
import { useViewMode } from "shared/contexts/ViewMode";

import { useTabsState } from "./Tabs.hooks";
import styles from "./Tabs.module.scss";
import { DEFAULT_TAB_ID } from "./Tabs.Provider";

export const Tabs = ({ appearance, tabType, className }) => {
  const { t } = useTranslation();
  const { tabs, activeTab, activeTabIndex, setActiveTab, tabsEnabled } =
    useTabsState();
  const { isMobile } = useViewMode();
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  const moveLine = useCallback(
    (i) => (el) => {
      return (tabsRef.current[i] = el);
    },
    []
  );

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];

      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  const handleClickOnTab = useCallback(
    (index, tab) => () => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  if (!tabsEnabled) {
    return null;
  }
  return (
    <>
      <div className={classNames(styles.tabs, styles[tabType], className)}>
        <ul className={classNames(styles["tabs-list"], styles[appearance])}>
          {!isMobile && (
            <span
              className={styles["underline"]}
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            ></span>
          )}
          {tabs?.map((tab, index) => (
            <li
              key={tab}
              ref={moveLine(index)}
              className={classNames(styles["tabs-item"], {
                [styles["tabs-item-active"]]: tab === activeTab,
              })}
              onClick={handleClickOnTab(index, tab)}
            >
              <Paragraph color="grey">
                {tab === DEFAULT_TAB_ID ? t("All") : t(tab)}
              </Paragraph>
            </li>
          ))}
          {isMobile && (
            <span
              className={styles["underline"]}
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            ></span>
          )}
        </ul>
      </div>
    </>
  );
};
