import { useViewMode } from "shared/contexts/ViewMode";

export const RenderIf = ({ children, viewMode }) => {
  const { mode } = useViewMode();

  if (viewMode === mode) {
    return children;
  }

  return null;
};
