import { contentWidgetsRegistry } from "components/widgets/content";
import { footerWidgetsRegistry } from "components/widgets/footer";
import { heroWidgetsRegistry } from "components/widgets/hero";
import { layoutWidgetsRegistry } from "components/widgets/layout-widget";
import React, { Suspense } from "react";

const WidgetFallback = ({ payload }) => {
  if (process.env.NODE_ENV === "development") {
    return <pre>{JSON.stringify(payload, null, 4)}</pre>;
  }

  return null;
};

const nameToRegistryMapping = {
  footer: footerWidgetsRegistry,
  content: {
    ...contentWidgetsRegistry,
    ...heroWidgetsRegistry,
  },
  "layout-widget": {
    ...layoutWidgetsRegistry,
  },
};

function Widget({ data, registryName, position }) {
  const { __component: widgetId, ...payload } = data;

  const resolvedRegistry = nameToRegistryMapping?.[registryName] ?? {};
  const ResolvedWidget =
    resolvedRegistry?.[widgetId]?.component ?? WidgetFallback;
  const ResolvedSkeleton =
    resolvedRegistry?.[widgetId]?.loader ?? WidgetFallback;

  return (
    <Suspense fallback={<ResolvedSkeleton />}>
      <ResolvedWidget payload={payload} position={position} />
    </Suspense>
  );
}

export function WidgetsRenderer({ items, registry, className }) {
  return (
    <div data-widget-root={registry} className={className}>
      {items?.map((data, index) => (
        <div
          data-widget-position={index + 1}
          key={`${data.__component}_${data.id}`}
        >
          <Widget data={data} registryName={registry} position={index + 1} />
        </div>
      ))}
    </div>
  );
}
