import { fetchAPI } from "features/cms";
import { buildWidgetInstanceId } from "features/widgets";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCollectedData } from "shared/contexts/PageData";
import { buildFeed, mergeWithBanners } from "shared/utils";

const FETCH_INITIAL_PAGE = 2;
const PAGESIZE = 5;

export const useCollectedNewsRender = ({ payload, widgetNumber }) => {
  const { query, locale } = useRouter();
  const courseBanner = useMemo(() => payload?.courseBanner ?? [], [payload]);
  const articleSlug = useMemo(() => query?.slug ?? null, [query?.slug]);

  const instanceId = buildWidgetInstanceId(widgetNumber, payload.id);
  const { articles, "article-categories": articleCategories } =
    useCollectedData(instanceId, [
      { name: "articles", defaultValue: [] },
      { name: "article-categories", defaultValue: [] },
    ]);

  const [activeCategory] = useMemo(
    () => articleCategories.filter(({ slug }) => slug === articleSlug),
    [articleCategories, articleSlug]
  );

  const [feed, setFeed] = useState([]);
  const [date, setDate] = useState(new Date());
  const [limit, setLimit] = useState(PAGESIZE);
  const [fetchPage, setfetchPage] = useState(FETCH_INITIAL_PAGE);

  const visibleBanners = useMemo(
    () =>
      courseBanner.filter((course) => {
        return course.courseBannerPosition <= limit;
      }),
    [limit, courseBanner]
  );

  useEffect(() => {
    const item = buildFeed({
      articles,
    });

    setLimit(PAGESIZE);
    setFeed(item);
  }, [articleSlug, articles]);

  const feedWithBanners = useMemo(() => {
    return mergeWithBanners([...feed], visibleBanners, "courseBannerPosition");
  }, [feed, visibleBanners]);

  useEffect(() => {
    if (feedWithBanners.length <= limit) {
      const collectedDataRender = async () => {
        const nextArticles = articleSlug
          ? await fetchAPI(`/articles`, {
              sort: "date:DESC",
              populate: {
                articleCategories: { populate: "deep,1" },
                previewImage: "*",
              },

              filters: {
                articleCategories: {
                  slug: {
                    $in: articleSlug,
                  },
                },
                date: {
                  $lte: date,
                },
              },
              pagination: {
                page: fetchPage,
                pageSize: PAGESIZE,
              },

              locale,
            })
          : await fetchAPI(`/articles`, {
              sort: "date:DESC",
              populate: {
                previewImage: "*",
              },
              filters: {
                date: {
                  $lte: date,
                },
              },
              pagination: {
                page: fetchPage,
                pageSize: PAGESIZE,
              },

              locale,
            });

        const temporaryFeed = buildFeed({
          articles: nextArticles?.data ?? [],
        });

        setFeed((currentFeed) => [...currentFeed, ...temporaryFeed]);
        setfetchPage((prevPage) => prevPage + 1);
      };

      collectedDataRender();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const isCompleted = useMemo(
    () => feedWithBanners.length < limit,
    [feedWithBanners.length, limit]
  );

  const renderNext = useCallback(() => {
    if (!isCompleted) {
      setLimit((limit) => limit + PAGESIZE);
    }
  }, [isCompleted]);

  return {
    feed: feedWithBanners,
    isCompleted,
    renderNext,
    limit,
    articleCategories,
    activeCategory,
  };
};
