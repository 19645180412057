import { Footer } from "components/Footer";
import { Header } from "components/Header";

export const Layout = ({ children }) => (
  <div>
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);
