import { fetchAPI } from "features/cms";
import { logError } from "features/logger";

const defaultValue = {
  hero: [],
  content: [],
};

export const fetchTemplatePageData = async ({ locale, debug }, path) => {
  try {
    const res = await fetchAPI(path, {}, locale);

    const currentData = res?.data ?? {};
    const { hero, content } = currentData ?? {};

    return Promise.resolve({
      templatedData: {
        hero: hero ?? [],
        content: content ?? [],
      },
      error: null,
    });
  } catch (e) {
    logError(e, { scope: "fetchaTemplatePageData", collection: "template" });
    return Promise.resolve({
      templatedData: defaultValue,
      error: {
        status: e.status,
      },
      debugData: debug
        ? {
            scope: "Error caught",
            error: {
              status: e.status,
              message: e.message,
            },
            response: null,
          }
        : null,
    });
  }
};
