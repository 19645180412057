// for url https://www.youtube.com/watch?v=:id
// for url https://youtu.be/:id
export const parseYouTubeId = (url) => {
  if (url) {
    const urlParts = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (urlParts[2] !== undefined) {
      return urlParts[2].split(/[^0-9a-z_\-]/i)[0];
    }
  }

  return null;
};
