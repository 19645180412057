import { RichText } from "components/RichTextWidget";
import { useTranslation } from "features/translations";
import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  Heading4,
  Icon,
  PageLink,
  Paragraph,
  RenderIf,
} from "shared/components";
import { VIEW_MODES } from "shared/contexts/ViewMode";
import { formatDate } from "shared/utils";

import styles from "./Banner.module.scss";

export const Banner = ({ item, className }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const date = useMemo(() => {
    return formatDate(item.startDate, locale);
  }, [item.startDate, locale]);

  return (
    <div className={className}>
      <PageLink
        slug={item?.course?.slug}
        directory="programs"
        className={styles.block}
      >
        <div className={styles.card}>
          <div className={styles.text}>
            <Heading4 color="light" className={styles.heading}>
              {item.heading}
            </Heading4>
            <RichText
              color="light"
              className={styles.description}
              value={item.description}
            />
            <Paragraph color="light" className={styles.date} appearance="bold">
              {date}
            </Paragraph>
          </div>
          <div className={styles.button}>
            <RenderIf viewMode={VIEW_MODES.DESKTOP}>
              <div className={styles.arrow}>
                <Icon className={styles.icon} name="arrow_right" />
              </div>
              <Paragraph
                color="light"
                className={styles.more}
                appearance="bigger"
              >
                {t("more")}
              </Paragraph>
            </RenderIf>
          </div>
        </div>
      </PageLink>
    </div>
  );
};
