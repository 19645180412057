import { createContext, useCallback } from "react";
import { useFormSubmit } from "shared/hooks";

import { useForm } from "./useForm";

export const FormContent = createContext({});

export const FormProvider = ({
  children,
  formData,
  type,
  metaData,
  onResetCallback,
}) => {
  const { title, fields, buttonText, statusErrorText, statusSuccessfulText } =
    formData;
  const { error, success, pending, onResetFormSubmit, onSubmit } =
    useFormSubmit(formData);

  const {
    formInfo,
    errors,
    canBeSubmitted,
    onChange,
    onResetFormHandler,
    onValidate,
    onHandle,
    getInputValue,
    getInputError,
  } = useForm({
    fields,
  });

  const onResetForm = useCallback(() => {
    onResetFormHandler();
    onResetFormSubmit();
    if (typeof onResetCallback === "function") {
      onResetCallback();
    }
  }, [onResetFormHandler, onResetFormSubmit, onResetCallback]);

  const value = {
    type,
    title,
    data: formInfo,
    metaData,
    buttonText,
    statusErrorText,
    statusSuccessfulText,
    fields,
    errors,
    canBeSubmitted,
    error,
    success,
    pending,
    onSubmit,
    onChange,
    onValidate,
    onHandle,
    onResetForm,
    getInputValue,
    getInputError,
  };

  return <FormContent.Provider value={value}>{children}</FormContent.Provider>;
};
