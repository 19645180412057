import { TeacherLink } from "./variations/TeacherLink";
import { TeacherList } from "./variations/TeacherList";

const EmptyWidget = () => null;

const RICH_WIDGET_TYPE = {
  teacher: TeacherList,
  teacher_link: TeacherLink,
  teacher_timing: TeacherList,
  _default: EmptyWidget,
};

export const RichTextWidgetRenderer = ({ rawData }) => {
  const chunks = rawData.replace(/[{}]/g, "").split(":");

  const [type, payload, timing] = chunks;

  const MiniWidget = RICH_WIDGET_TYPE[type] ?? RICH_WIDGET_TYPE._default;

  return <MiniWidget payload={payload} timing={timing} />;
  return null;
};
