import classNames from "classnames";
import { useEffect, useState } from "react";

import styles from "./RichTextContainer.module.scss";

export const RichTextContainer = ({ children, className, color = "dark" }) => {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <>
      {domLoaded && (
        <article
          className={classNames(
            styles.wysiwyg,
            className,
            styles[`color-${color}`]
          )}
        >
          {children}
        </article>
      )}
    </>
  );
};
