import { useTranslation } from "features/translations";
import { Heading6 } from "shared/components";

import { TagItem } from "./TagItem";
import styles from "./Tags.module.scss";

export const AllTags = ({ tags, activeTag }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Heading6 className={styles.heading} color="dark" appearance="bold">
        {t("allTags")}
      </Heading6>
      <div className={styles.tags}>
        {tags.map((tag) => (
          <TagItem key={tag.id} tag={tag} activeTag={tag.slug === activeTag} />
        ))}
      </div>
    </div>
  );
};
