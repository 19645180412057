import classNames from "classnames";
import { CallButton } from "components/CallButton";
import { LanguageSelector } from "components/LanguageSelector";
import { SocialSharing } from "components/SocialSharing";
import { useTranslation } from "features/translations";
import { Container, PageLink, Paragraph } from "shared/components";
import { SiteLogo } from "shared/components/SiteLogo";
import { MenuItem } from "shared/components/Typography";
import { useMenusState } from "shared/contexts/Menus";

import styles from "./FooterDesktop.module.scss";

export const FooterDesktop = ({ year }) => {
  const { t } = useTranslation();
  const { footerData } = useMenusState();

  return (
    <footer className={styles.wrapper}>
      <Container className={classNames(styles.row, styles.links)}>
        <div className={styles.col}>
          <SiteLogo type="footer" />
        </div>
        {footerData.map(({ id, title, items }) => (
          <div className={styles.col} key={id}>
            <div className={styles.selector}>
              <div className={styles.title}>{t(title)}</div>

              <nav className={styles.list}>
                {items?.map(({ title, url, target }) => (
                  <PageLink link={url} key={title} target={target}>
                    <Paragraph
                      className={styles.item}
                      appearance="smaller"
                      uppercase
                    >
                      {title}
                    </Paragraph>
                  </PageLink>
                ))}
              </nav>
            </div>
          </div>
        ))}
      </Container>
      <Container className={classNames(styles.row, styles.info)}>
        <div className={styles.col}>
          <Paragraph
            appearance="smaller"
            color="light"
            className={styles.label}
          >
            © {year} <br />
            {t("All rights reserved")}
          </Paragraph>
        </div>
        <div className={styles.col}>
          <CallButton theme="light" hover="fill" />
        </div>
        <div className={styles.col}>
          <SocialSharing inline />
        </div>

        <div className={styles.col}>
          <MenuItem className={styles.label} color="light">
            {t("Lang/Мова")}
          </MenuItem>
          <LanguageSelector appearance="light" inline />
        </div>
        <div className={styles.col}>
          <MenuItem className={styles.label} color="light">
            {t("Desing and development")}
          </MenuItem>
          <Paragraph appearance="bold" color="light" uppercase>
            <a href="https://www.lazarev.agency/">LAZAREV.</a> &{" "}
            <a href="https://aurocraft.com/ua">AUROCRAFT</a>
          </Paragraph>
        </div>
      </Container>
    </footer>
  );
};
