import { Paragraph } from "shared/components";

import styles from "./DetailsBlock.module.scss";

export const DetailsBlock = ({ items, className }) => {
  return (
    <div className={className}>
      {items.map((item) => {
        if (item.text)
          return (
            <div className={styles.details} key={item.id}>
              <Paragraph color="grey" uppercase className={styles.label}>
                {item.label}
              </Paragraph>
              <Paragraph color="dark" appearance="bold" className={styles.text}>
                {item.text}
              </Paragraph>
            </div>
          );
      })}
    </div>
  );
};
