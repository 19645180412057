import { InputCheckbox } from "./InputCheckbox";
import { InputEmail } from "./InputEmail";
import { InputTel } from "./InputTel";
import { InputText } from "./InputText";

export const DefaultInput = () => null;

export const TYPE_INPUT_MAPPING = {
  checkbox: InputCheckbox,
  text: InputText,
  email: InputEmail,
  tel: InputTel,
  default: DefaultInput,
};

export const InputType = ({ ...input }) => {
  const InputItem =
    TYPE_INPUT_MAPPING[input.type] || TYPE_INPUT_MAPPING.default;

  return <InputItem {...input} />;
};
