import classNames from "classnames";

import { Typography } from "../Typography";
import styles from "./Heading5.module.scss";

export const Heading5 = ({
  as = "h5",
  className,
  color,
  children,
  plain = false,
}) => (
  <Typography
    className={classNames(className, styles.element)}
    color={color}
    as={as}
    plain={plain}
  >
    {children}
  </Typography>
);
