import { fetchAPI } from "features/cms";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

export const useCollectedTeachers = ({ payload }) => {
  const { locale } = useRouter();
  const teachersSlug = useMemo(() => payload?.split(",") ?? [], [payload]);
  const [teachers, setTeachers] = useState(null);

  useEffect(() => {
    if (teachersSlug.length > 0) {
      const collectedDataRender = async () => {
        const teachersData = (await fetchAPI(
          `/teachers`,
          {
            populate: {
              photo: "*",
            },
            fields: ["title", "slug", "description"],
            filters: {
              slug: {
                $in: teachersSlug,
              },
            },
          },
          locale
        )) ?? { data: [] };

        setTeachers(teachersData?.data);
      };

      collectedDataRender();
    }
  }, [locale, teachersSlug, teachersSlug.length]);

  return { teachers };
};
