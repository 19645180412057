import classNames from "classnames";
import { RichString } from "shared/components";

import styles from "./Typography.module.scss";

// type color = 'dark'|'light'|'grey'|'brand'
// type as = 'p'|'span'|'h1'...'h8'
export const Typography = ({
  as: Element,
  attributes = {},
  children,
  className,
  color,
  uppercase,
  plain,
}) => {
  return (
    <Element
      className={classNames(
        className,
        styles.element,
        styles[`color-${color}`],
        {
          [styles.uppercase]: uppercase,
        }
      )}
      {...attributes}
    >
      {plain ? children : <RichString value={children} />}
    </Element>
  );
};

Typography.defaultProps = {
  as: "p",
  color: "dark",
};
