import React, { useMemo } from "react";

import { Article } from "../Article";
import { Banner } from "../Banner";

export const TYPE_CARD_MAPPING = {
  banner: Banner,
  article: Article,
  default: Article,
};

export const NewsItems = ({ items, className, limit }) => {
  const slicedItems = useMemo(() => {
    return items.slice(0, limit);
  }, [items, limit]);

  return (
    <div className={className}>
      {slicedItems.map((item) => {
        const Card = TYPE_CARD_MAPPING[item.type] || TYPE_CARD_MAPPING.default;

        return (
          <Card key={`${item.type}_${item.id}_${item.title}`} item={item} />
        );
      })}
    </div>
  );
};
