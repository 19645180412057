import classNames from "classnames";
import { Heading6, Icon, ImageLite, Paragraph } from "shared/components";

import styles from "./Blockquote.module.scss";

export const Blockquote = ({ image, author, position, quote, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.quote}>
        <Icon name="blockquote" className={styles.icon} />
        <Heading6 className={styles.text} color="light">
          {quote}
        </Heading6>
      </div>
      <div className={styles.person}>
        <ImageLite image={image} className={styles.image} />
        <div className={styles.details}>
          <Paragraph className={styles.author} color="light">
            {author}
          </Paragraph>
          <Paragraph className={styles.position} color="grey">
            {position}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
