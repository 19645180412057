import { useCallback, useMemo, useState } from "react";

import { validate, validateOne } from "./validate";

export const useForm = ({ fields }) => {
  const initialValues = useMemo(() => {
    return fields.reduce((acc, { name, defaultValue }) => {
      return {
        ...acc,
        [name]: defaultValue,
      };
    }, {});
  }, [fields]);

  const [formInfo, setFormInfo] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [submitReady, setSubmitReady] = useState(false);

  const onChange = useCallback((e) => {
    setFormInfo((currentFormInfo) => ({
      ...currentFormInfo,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onValidate = useCallback(
    (e) => {
      const fieldName = e.target.name;
      const fieldValue = formInfo[e.target.name];
      const [fieldConfig] = fields.filter(({ name }) => {
        return name === e.target.name;
      });

      const validationErrors = validateOne(
        fieldName,
        fieldValue,
        fieldConfig.validator
      );

      setErrors((errors) => ({ ...errors, ...validationErrors }));
    },
    [fields, formInfo]
  );

  const onHandle = useCallback(() => {
    const validateErrors = validate(formInfo, fields);
    setErrors((errors) => ({ ...errors, ...validateErrors }));

    if (Object.values(validateErrors).filter(Boolean).length > 0) {
      setSubmitReady(false);
    } else {
      setSubmitReady(true);
    }
  }, [fields, formInfo]);

  const onResetFormHandler = useCallback(() => {
    setFormInfo((prevValues) => initialValues);
    setSubmitReady(false);
  }, [initialValues]);

  const getInputValue = useCallback(
    (name) => {
      return formInfo[name];
    },
    [formInfo]
  );

  const getInputError = useCallback(
    (name) => {
      return errors[name];
    },
    [errors]
  );

  return {
    formInfo,
    errors,
    canBeSubmitted: submitReady,
    getInputValue,
    getInputError,
    setErrors,
    onChange,
    onValidate,
    onResetFormHandler,
    onHandle,
  };
};
