import { buildWidgetId } from "./buildWidgetId";

export const registerWidget =
  (registryPrefix) =>
  (variation, dynamicComponent, skeleton = null) => ({
    [buildWidgetId(variation, registryPrefix)]: {
      component: dynamicComponent,
      ...(skeleton && { skeleton }),
    },
  });
