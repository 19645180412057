import { useCallback, useEffect, useRef } from "react";

export const useOffsetScroll = ({ widgetOffset, position }) => {
  const widgetsRoot = useRef(null);
  const targetPosition = position + widgetOffset ?? 0;

  useEffect(() => {
    widgetsRoot.current = document.querySelector(
      `[data-widget-root="content"]`
    );
  }, []);

  const scrollToTarget = useCallback(() => {
    if (widgetsRoot.current === null) {
      return;
    }

    const targetWidget = widgetsRoot.current.querySelector(
      `[data-widget-position="${targetPosition}"]`
    );

    if (targetWidget === null) {
      return;
    }

    const scrollPosition =
      targetWidget.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: "smooth",
    });
  }, [targetPosition]);
  return { scrollToTarget };
};
