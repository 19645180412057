import classNames from "classnames";

import styles from "./Icon.module.scss";
import { NativeIcon } from "./NativeIcon";

export const Icon = ({ className, name, theme = "default" }) => (
  <span className={classNames(styles.image, styles[theme], className)}>
    <NativeIcon name={name} />
  </span>
);
