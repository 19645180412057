export const getLinkParams = (directory, slug) => {
  const slugParamsMapping = {
    home: {
      pathname: "/",
    },
    teachers: {
      pathname: "/team/teachers",
    },
  };

  const directoryParamsMapping = {
    programs: {
      pathname: "/programs/[slug]",
      query: { slug },
    },
    events: {
      pathname: "/events/[slug]",
      query: { slug },
    },
    news: {
      pathname: "/news/[slug]",
      query: { slug },
    },
    newsCategory: {
      pathname: "/news/category/[slug]",
      query: { slug },
    },

    projects: {
      pathname: "/graduates/projects/[slug]",
      query: { slug },
    },
    teachers: {
      pathname: "/team/teachers/[slug]",
      query: { slug },
    },
  };

  if (directory) {
    return directoryParamsMapping[directory];
  }

  return slugParamsMapping[slug]
    ? slugParamsMapping[slug]
    : {
        pathname: "/[slug]",
        query: { slug },
      };
};
