import { BurgerMenu } from "components/BurgerMenu";
import { CallButton } from "components/CallButton";
import { LanguageSelector } from "components/LanguageSelector";
import { SignInButton } from "components/SignInButton";
import { SiteLogo } from "shared/components";

import styles from "./MobileHeader.module.scss";

export const MobileHeader = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.left}>
        <CallButton noTitle theme="dark-reverse" hover="none" />
        <SignInButton noTitle />
      </div>
      <SiteLogo />
      <div className={styles.right}>
        <LanguageSelector />
        <BurgerMenu />
      </div>
    </header>
  );
};
