import { WIDGETS_REGISTRY } from "features/widgets/widgets.config";

export const buildWidgetId = (
  variation,
  widgetType = WIDGETS_REGISTRY.CONTENT
) => `${widgetType}.${variation}`;

export const buildWidgetInstanceId = (
  variation,
  instanceId,
  widgetType = WIDGETS_REGISTRY.CONTENT
) => `${widgetType}.${variation}.${instanceId}`;
