import { CallButton } from "components/CallButton";
import { LanguageSelector } from "components/LanguageSelector";
import { Navigation } from "components/Navigation";
import { SignInButton } from "components/SignInButton";
import { SocialSharing } from "components/SocialSharing";
import { Container, SiteLogo } from "shared/components";

import styles from "./DesktopHeader.module.scss";

export const DesktopHeader = () => {
  return (
    <header className={styles.wrapper}>
      <Container className={styles.inner}>
        <SiteLogo />
        <Navigation />
        <div className={styles.actions}>
          <CallButton />
          <SignInButton />
          <LanguageSelector />
        </div>
        <SocialSharing />
      </Container>
    </header>
  );
};
