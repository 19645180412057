import { registerWidget, WIDGETS_REGISTRY } from "features/widgets";

import { DynamicContent002 } from "./Content002";
import { DynamicContent003 } from "./Content003";
import { DynamicContent005 } from "./Content005";
import { DynamicContent006 } from "./Content006";
import { DynamicContent007 } from "./Content007";
import { DynamicContent008 } from "./Content008";
import { DynamicContent009 } from "./Content009";
import { DynamicContent010 } from "./Content010";
import { DynamicContent012 } from "./Content012";
import { DynamicContent013 } from "./Content013";
import { DynamicContent015 } from "./Content015";
import { DynamicContent017 } from "./Content017";
import { DynamicContent018 } from "./Content018";
import { DynamicContent019 } from "./Content019";
import { DynamicContent020 } from "./Content020";
import { DynamicContent021 } from "./Content021";
import { DynamicContent022 } from "./Content022";
import { DynamicContent023 } from "./Content023";
import { DynamicContent024 } from "./Content024";
import { DynamicContent025 } from "./Content025";
import { DynamicContent027 } from "./Content027";
import { DynamicContent035 } from "./Content035";
import { DynamicContent038 } from "./Content038";
import { DynamicContent040 } from "./Content040";
import { DynamicContent041 } from "./Content041";
import { DynamicContent042 } from "./Content042";
import { DynamicContent043 } from "./Content043";
import { DynamicContent044 } from "./Content044";
import { DynamicContent045 } from "./Content045";
import { DynamicContent046 } from "./Content046";
import { DynamicContent049 } from "./Content049";
import { DynamicContent050 } from "./Content050";
import { DynamicContent051 } from "./Content051";
import { DynamicContent052 } from "./Content052";
import { DynamicContent053 } from "./Content053";
import { DynamicContent054 } from "./Content054";
import { DynamicContent055 } from "./Content055";
import { DynamicContent056 } from "./Content056";
import { DynamicContent057 } from "./Content057";
import { DynamicContent058 } from "./Content058";
import { DynamicContent059 } from "./Content059";
import { DynamicContent060 } from "./Content060";
import { DynamicContent061 } from "./Content061";
import { DynamicContent062 } from "./Content062";
import { DynamicContent064 } from "./Content064";
import { DynamicContent066 } from "./Content066";
import { DynamicContent067 } from "./Content067";
import { DynamicContent068 } from "./Content068";
import { DynamicContent069 } from "./Content069";
import { DynamicContent071 } from "./Content071";
import { DynamicContent072 } from "./Content072";
import { DynamicContent074 } from "./Content074";
import { DynamicContent075 } from "./Content075";
import { DynamicContent076 } from "./Content076";
import { DynamicContent079 } from "./Content079";
import { DynamicContent080 } from "./Content080";
import { DynamicContent083 } from "./Content083";
import { DynamicContent084 } from "./Content084";
import { DynamicContent085 } from "./Content085";
import { DynamicContent0802 } from "./Content0802";

const registerContentWidget = registerWidget(WIDGETS_REGISTRY.CONTENT);

export const contentWidgetsRegistry = {
  ...registerContentWidget("002", DynamicContent002),
  ...registerContentWidget("003", DynamicContent003),
  ...registerContentWidget("005", DynamicContent005),
  ...registerContentWidget("006", DynamicContent006),
  ...registerContentWidget("007", DynamicContent007),
  ...registerContentWidget("008", DynamicContent008),
  ...registerContentWidget("009", DynamicContent009),
  ...registerContentWidget("010", DynamicContent010),
  ...registerContentWidget("012", DynamicContent012),
  ...registerContentWidget("013", DynamicContent013),
  ...registerContentWidget("015", DynamicContent015),
  ...registerContentWidget("017", DynamicContent017),
  ...registerContentWidget("018", DynamicContent018),
  ...registerContentWidget("019", DynamicContent019),
  ...registerContentWidget("020", DynamicContent020),
  ...registerContentWidget("021", DynamicContent021),
  ...registerContentWidget("022", DynamicContent022),
  ...registerContentWidget("023", DynamicContent023),
  ...registerContentWidget("024", DynamicContent024),
  ...registerContentWidget("025", DynamicContent025),
  ...registerContentWidget("027", DynamicContent027),
  ...registerContentWidget("035", DynamicContent035),
  ...registerContentWidget("038", DynamicContent038),
  ...registerContentWidget("040", DynamicContent040),
  ...registerContentWidget("041", DynamicContent041),
  ...registerContentWidget("042", DynamicContent042),
  ...registerContentWidget("043", DynamicContent043),
  ...registerContentWidget("044", DynamicContent044),
  ...registerContentWidget("045", DynamicContent045),
  ...registerContentWidget("046", DynamicContent046),
  ...registerContentWidget("049", DynamicContent049),
  ...registerContentWidget("050", DynamicContent050),
  ...registerContentWidget("051", DynamicContent051),
  ...registerContentWidget("052", DynamicContent052),
  ...registerContentWidget("053", DynamicContent053),
  ...registerContentWidget("054", DynamicContent054),
  ...registerContentWidget("055", DynamicContent055),
  ...registerContentWidget("056", DynamicContent056),
  ...registerContentWidget("057", DynamicContent057),
  ...registerContentWidget("058", DynamicContent058),
  ...registerContentWidget("059", DynamicContent059),
  ...registerContentWidget("060", DynamicContent060),
  ...registerContentWidget("061", DynamicContent061),
  ...registerContentWidget("062", DynamicContent062),
  ...registerContentWidget("064", DynamicContent064),
  ...registerContentWidget("066", DynamicContent066),
  ...registerContentWidget("067", DynamicContent067),
  ...registerContentWidget("068", DynamicContent068),
  ...registerContentWidget("069", DynamicContent069),
  ...registerContentWidget("071", DynamicContent071),
  ...registerContentWidget("072", DynamicContent072),
  ...registerContentWidget("074", DynamicContent074),
  ...registerContentWidget("075", DynamicContent075),
  ...registerContentWidget("076", DynamicContent076),
  ...registerContentWidget("079", DynamicContent079),
  ...registerContentWidget("080", DynamicContent080),
  ...registerContentWidget("083", DynamicContent083),
  ...registerContentWidget("084", DynamicContent084),
  ...registerContentWidget("085", DynamicContent085),
  ...registerContentWidget("080-2", DynamicContent0802),
};
