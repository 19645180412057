import classNames from "classnames";
import { useTranslation } from "features/translations";
import { Button } from "shared/components";

export const SignInButton = ({
  noTitle,
  noIcon = false,
  className,
  theme = "dark",
}) => {
  const { t } = useTranslation();

  return (
    <Button
      as="a"
      htmlAttributes={{
        href: "https://study.mim.kyiv.ua/login/index.php",
        target: "_blank",
      }}
      icon={!noIcon && "profile"}
      className={className}
      theme={theme}
    >
      {!noTitle ? t("Log_into_the_cabinet") : null}
    </Button>
  );
};
