import { useTranslation } from "features/translations";
import Router from "next/router";
import { useEffect, useMemo } from "react";
import { Action, Icon, ModalWrapper, Paragraph } from "shared/components";
import { useToggle } from "shared/hooks";

import { AllTags } from "./AllTags";
import { TagItem } from "./TagItem";
import styles from "./Tags.module.scss";

export const PopularTags = ({ tags, activeTag }) => {
  const { t } = useTranslation();

  const { opened, onOpen, onClose } = useToggle();

  const popularTags = useMemo(() => tags.filter((tag) => tag.popular), [tags]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", onClose);

    return () => {
      Router.events.off("routeChangeComplete", onClose);
    };
  }, [onClose]);

  return (
    <div className={styles.wrapper}>
      <Paragraph
        as="p"
        className={styles.title}
        color="grey"
        appearance="bigger"
      >
        {t("popularTags")}
      </Paragraph>
      <div className={styles.tags}>
        {popularTags.map((tag) => (
          <TagItem key={tag.id} tag={tag} activeTag={tag.slug === activeTag} />
        ))}
      </div>
      <Action onClick={onOpen} className={styles.button}>
        <span className={styles.arrow}>
          <Paragraph
            as="p"
            color="brand"
            appearance="bigger"
            className={styles.label}
          >
            {t("allTags")}
          </Paragraph>
          <Icon className={styles.icon} name="arrow_right" />
        </span>
      </Action>
      {opened && (
        <ModalWrapper onCloseModal={onClose}>
          <AllTags tags={tags} activeTag={activeTag} />
        </ModalWrapper>
      )}
    </div>
  );
};
