import classNames from "classnames";
import { CallButton } from "components/CallButton";
import { LanguageSelector } from "components/LanguageSelector";
import { SocialSharing } from "components/SocialSharing";
import { useTranslation } from "features/translations";
import { useCallback, useState } from "react";
import { Container, Icon, PageLink, Paragraph } from "shared/components";
import { SiteLogo } from "shared/components/SiteLogo";
import { MenuItem } from "shared/components/Typography";
import { useMenusState } from "shared/contexts/Menus";

import styles from "./FooterMobile.module.scss";

export const FooterMobile = ({ year }) => {
  const { t } = useTranslation();
  const { footerData } = useMenusState();

  const [open, setOpen] = useState(null);

  const onToggle = useCallback(
    (title) => () => {
      setOpen((current) => (current === title ? null : title));
    },
    []
  );

  return (
    <footer className={styles.wrapper}>
      <Container className={styles["links-container"]}>
        <div className={styles.links}>
          {footerData.map(({ id, title, items }) => (
            <div
              key={id}
              onClick={onToggle(title)}
              className={styles.interactive}
            >
              <div
                className={classNames(styles.title, {
                  [styles.opened]: open === title,
                })}
              >
                {t(title)}

                <Icon
                  name="chevron_down"
                  theme="white"
                  className={styles.icon}
                />
              </div>

              {open === title && (
                <nav className={styles.list}>
                  {items.map(({ title, url, target }) => (
                    <PageLink link={url} key={title} target={target}>
                      <Paragraph
                        className={styles.item}
                        appearance="smaller"
                        uppercase
                      >
                        {title}
                      </Paragraph>
                    </PageLink>
                  ))}
                </nav>
              )}
            </div>
          ))}
        </div>
      </Container>
      <Container className={styles["social-container"]}>
        <CallButton theme="light" hover="fill" />
        <SocialSharing inline />
      </Container>
      <Container className={styles.info}>
        <div className={styles.logo}>
          <SiteLogo type="footer" />
        </div>
        <div>
          <Paragraph
            appearance="smaller"
            color="light"
            className={styles.label}
          >
            © {year} {t("All rights reserved")}
          </Paragraph>
          <Paragraph
            appearance="smaller"
            color="light"
            className={styles.label}
          >
            {t("International institute of management (MIM-Kyiv)")}
          </Paragraph>
        </div>
      </Container>
      <Container className={styles.credentials}>
        <div className={styles.left}>
          <MenuItem className={styles.label} color="light">
            {t("Desing and development")}
          </MenuItem>
          <div className={styles.brands}>
            <PageLink link="https://www.lazarev.agency/">
              <Paragraph appearance="bold" color="light" uppercase>
                LAZAREV.
              </Paragraph>
            </PageLink>
            <Paragraph
              className={styles.ampersand}
              appearance="bold"
              color="light"
            >
              &
            </Paragraph>
            <PageLink link="https://aurocraft.com/ua">
              <Paragraph appearance="bold" color="light" uppercase>
                AUROCRAFT
              </Paragraph>
            </PageLink>
          </div>
        </div>
        <div className={styles.right}>
          <MenuItem className={styles.label} color="light">
            {t("Lang/Мова")}
          </MenuItem>
          <LanguageSelector appearance="light" inline />
        </div>
      </Container>
    </footer>
  );
};
